import React, {useRef, useState} from 'react'
import Footer from '../footer'
import {BsFillTelephoneOutboundFill } from 'react-icons/bs'
import {FaTiktok, FaInstagram, FaTelegram, FaFacebook, FaTelegramPlane} from 'react-icons/fa'
import './footer.css'
import emailjs from "@emailjs/browser";
import swal from 'sweetalert';
import "../../pages/Contact/info.css"
import {Link} from "react-router-dom";

export function FooterContainer() {
    const form = useRef();
    const [result , showResult ] = useState(false)
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_5mahi",
                "template_fu31jef",
                form.current,
                e.target,
                "B1zbCh6OTCiPMnlpO"
            )
            .then(
                swal({
                    title: "Successfully sent!",
                    text: "We Received your message thank you for ur comment !",
                    icon: "success",
                    button: "ok",
                })
            );
        e.target.reset();
    };
    return (
        <div>
        <Footer >
        <
        Footer.Wrapper >
        <
        Footer.Row >
        <
        Footer.Column >
        <
        Footer.Title > About Us < /Footer.Title> <
        Footer.Link href = "#" > Story < /Footer.Link> <
        Footer.Link href = "#" > Clients < /Footer.Link> <
        Footer.Link href = "#" > Testimonials < /Footer.Link> <
        /Footer.Column> <
        Footer.Column >
        <
        Footer.Title > Services < /Footer.Title> <
        Footer.Link href = "#" > Tapiseri < /Footer.Link> <
        Footer.Link href = "#" > Car Decor < /Footer.Link> <
        Footer.Link href = "#" > Car Decor Shop < /Footer.Link>
        <
        /Footer.Column> <
        Footer.Column >
        <
        Footer.Title > Get In touch with Us < /Footer.Title>
       <Footer.Link>     <BsFillTelephoneOutboundFill className='phone' /> 0913852420</Footer.Link>
            <Footer.Link>     <BsFillTelephoneOutboundFill className='phone' /> 0937654505</Footer.Link>

            {/*<form action="#" ref={form} onSubmit={sendEmail}>*/}
            {/*    <label>Full Name </label>*/}
            {/*    <input type='name' placeholder='Enter your name here' name='user_name' />*/}
            {/*    <label> Your Email</label>*/}
            {/*    <input type='email' placeholder='Enter ur email here' name='user_email' />*/}
            {/*    <label>Message</label>*/}
            {/*    <input type='text'  placeholder='How can we help you? Or you us?'/>*/}
            {/*    <input type="submit" value='send'  name='message' className='form-submit'/>*/}
            {/*</form>*/}

            <a href="https://t.me/Abeltapiseri" className="chatLink">  <FaTelegramPlane/> Chat Us On Telegram</a>

        <
        /Footer.Column> <
        Footer.Column >
        <
        Footer.Title > Social  Media Accounts < /Footer.Title> <
        Footer.Link href = "https://www.facebook.com/abel.livee?mibextid=ZbWKwL"  > <FaFacebook/> Facebook < /Footer.Link> <
        Footer.Link href = "https://instagram.com/abeltapiseri?igshid=ZDdkNTZiNTM=" > <FaInstagram/> Instagram < /Footer.Link> <
        Footer.Link href = "https://www.tiktok.com/@abelcartapseryanddecor?_t=8ZiH9o5oG6I&_r=1" > <FaTiktok/> TikTok < /Footer.Link>
        <Footer.Link href = "https://t.me/aballl" > <FaTelegram/> Telegram < /Footer.Link>

        </Footer.Column>
        </Footer.Row>
        < /Footer.Wrapper>
    </Footer>
            <div className="footer">
                <p className="footer-copyright">
                    Website By ግራር Software Solutions. {new Date().getFullYear()}
                </p>
            </div>
        </div>
    )
}
