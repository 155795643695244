import React, { useRef } from "react";
import Info from './info'
import './contact.css'
import  Form from './form'
import Map from './map'
import mapi from "../../data/image/img.png"
const Contact = () => {

    return (
        <div>
            <a href="https://www.google.com/maps/place/Abel+Tapiseri+And+Car+Decor/@9.0025258,38.6920897,17z/data=!3m1!4b1!4m6!3m5!1s0x164b87d1ce81e371:0xb02567f504cce4a7!8m2!3d9.0025221!4d38.693613!16s%2Fg%2F11tt5t2g3b">
                <img
                    className="mapi"
                    src={mapi} alt=""/>
            </a>

            {/*<Map/>*/}
        <div className="contact-section">

            <Info />
            <Form/>
        </div>

        </div>
    );
};

export default Contact;
