import React from 'react'
import Cards from "./Cards";
import OurClients from "./ourClients";
const About = (props) => {
  return (
    <div className="container">
    
        <Cards/>
        <OurClients/>
    </div>
  )
}
export default About;
