// import React from 'react';
// import '../Home/Expriance.css';
// import CardItem from '../Home/CardItem';
// import car1 from '../../data/image/car7.jpg'
// import car2 from '../../data/image/local1.png'
// import car3 from '../../data/image/car2.png'
// import { GiCarSeat ,IoCarSportSharp, BsShopWindow} from "react-icons/all";
//
// function Services() {
//     return (
//         <div className='cards'>
//             <h1>Abel Tapiseri & Car Decor!</h1>
//             <h5>Check Out These Incredible Services</h5>
//             <div className='cards__container'>
//                 <div className='cards__wrapper'>
//                     <ul className='cards__items'>
//                         <CardItem
//                             icon={ <GiCarSeat />}
//                             src={car3}
//                             text='Explore the Conformable  & Beautifully Car sit '
//                             label='Luxury'
//                             path='/service'
//                         />
//                         <CardItem
//                             icon={<IoCarSportSharp  />}
//                             src={car2}
//                             text='We Design Interior Beauty Of Cars With Full Quality'
//                             label='Luxury'
//                             path='/service'
//                         />
//                         <CardItem
//                             icon={ <BsShopWindow  />
//                             }
//                             src={car1}
//                             text='Check Out The Adequate Price Of Tapiseries'
//                             label='Luxury'
//                             path='/service'
//                         />
//                     </ul>
//                 </div>
//             </div>
//         </div>
//     );
// }
//
// export default Services;

import React from 'react'
import Tapiseri from "./tapiseri";
import Cardecor from "./cardecor";
import Shop from "./shop";
const Services = () => {
    return (
        <div className="container">

          <Tapiseri/>
            <Cardecor/>
            <Shop/>

        </div>
    )
}
export default Services;
