import React from 'react';
import '../Home/Cards.css';
import shop from '../../data/video/shop.mp4'
import VideoItem from "../Home/videoitem";
function Shop() {
    return (
        <div className='cards'>
            <h1>Visit our Shop</h1>
            <h5>Check Out The Adequate Price Of  Our Car Decor shop</h5>
            <div className=''>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                        <VideoItem
                            src={shop}
                            text='car speaker, pasto polish , car tap , smoking stop car wax , car sit , cleaner , motor flash , car filtrate , car spoiler ... '
                            label='Luxury'
                            path='/service'
                        />
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Shop;
