import React from 'react'
import {FaTiktok, FaInstagram, FaTelegram, FaFacebook, FaTwitterSquare} from 'react-icons/fa'
import { ImLocation2 } from 'react-icons/im'
import {MdEmail} from 'react-icons/md'
import {BsFillTelephoneOutboundFill ,BsLinkedin } from 'react-icons/bs'
import {BiTimeFive} from 'react-icons/bi'
import './info.css'
import './contact.css'
const phoneNumber  = '+251 913852420'  + ' '  + '+251 937654505'
const contactDetails = [
    {
        value: 'Addis Ababa Ethiopia  betel In Front Of  Meskid  ',
        icon: <ImLocation2 className='icon-color'/>
    },
    {
        value: ' Sister Company location Addis Ababa Ethiopia  betel Beside Bicha fok Dubai Tapiseri  ',
        icon: <ImLocation2 className='icon-color'/>
    },
    { value: phoneNumber, icon: <BsFillTelephoneOutboundFill className='icon-color' /> },
    { value: 'abeltapiseri@gmail.com', icon: <MdEmail className='icon-color'/> },
    { value: 'Monday - Sunday 8:00AM - 8:00PM 12 Working hr per day To serve u better ', icon: <BiTimeFive className='icon-color'/> },
]


const renderContactDetails = () =>
    contactDetails.map(detail => (
        <p key={detail.value} className="info-detail">
            <div>
                {detail.icon}
                {detail.value}
            </div>
        </p>
    ))
const renderSisDetails = () =>
    contactDetails.map(detail => (
        <p key={detail.value} className="info-detail">
            <div>
                {detail.icon}
                {detail.val2}
            </div>
        </p>
    ))
const Info = () => (
    <section className="info">
        <hr className='spacing'/>
        <h2 className="info-h2">Contact information</h2>
        <div className="info-details-container">{renderContactDetails()}</div>
        <div className="info-icons-container"  >
            <a className="info-icon" href= "" >
                <BsLinkedin/>
            </a>
             <a className="instagram-icon" href= "https://instagram.com/abeltapiseri?igshid=ZDdkNTZiNTM=" >
                <FaInstagram/>
            </a>
            <a  className="telegram-icon" href="https://t.me/aballl">
                <FaTelegram/>
            </a>
            <a className="facebook-icon" href= "https://www.facebook.com/abel.livee?mibextid=ZbWKwL" >
                <FaFacebook/>
            </a>
            <a className="tiktok-icon"  href = "https://www.tiktok.com/@abelcartapseryanddecor?_t=8ZiH9o5oG6I&_r=1" >
                <FaTiktok/>
            </a>
            <a className="info-icon" href= "" >
                <FaTwitterSquare/>
            </a>
        </div>

    </section>
)

export default Info
