import React from 'react'
import Cards from "./Cards";
const Home = () => {
  return (
    <div className="container">
    
   <Cards className="home_container"/>
    
  </div>
  )
}
export default Home;
