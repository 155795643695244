import React from 'react';
import '../Home/Cards.css';
import CardItem from '../Home/CardItem';
import car1 from '../../data/image/aTapiseri/cardecor/c1.png.jpg'
import car2 from '../../data/image/aTapiseri/cardecor/c2.png.jpg'
import car3 from '../../data/image/aTapiseri/cardecor/c3.png.jpg'
import car4 from '../../data/image/aTapiseri/cardecor/c4.png.jpg'
import car5 from '../../data/image/aTapiseri/cardecor/c5.png.jpg'
import car6 from '../../data/image/aTapiseri/cardecor/c6.png.jpg'
function Cardecor() {
    return (
        <div className='cards'>
            <h1>Our Car Decor Services</h1>
            <h5>We Design Interior Beauty Of Cars With Full Quality</h5>
            <div className='cards__container'>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                        <CardItem
                            src={car1}
                            text='We Design Interior Beauty Of Cars With Full Quality'
                            label='Luxury'
                            path='/service'
                        />
                        <CardItem
                            src={car2}
                            label='Luxury'
                            path='/service'
                        />
                        <CardItem
                            src={car3}
                            label='Luxury'
                            path='service'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                            src={car4}
                            label='Luxury'
                            path='/service'
                        />
                        <CardItem
                            src= {car5}
                            label='Luxury'
                            path='/service'
                        />
                        <CardItem
                            src={car6}
                            text=''
                            label='Luxury'
                            path='/service'
                        />
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Cardecor;
