import React, {useRef, useState} from 'react'
import emailjs from "@emailjs/browser";

import './form.css'
import swal from "sweetalert";

// eslint-disable-next-line react-hooks/rules-of-hooks

const formInputs = [
    { id: 'name', type: 'text', label: 'Your name', placeholder: 'enter Ur name here',  name :'user_name' , value : " name is required "},
    {
        id: 'tel',
        type: 'tel',
        label: 'Phone number',
        placeholder: 'enter ur phonemuber ',
        name : 'phone_number' ,

    },
    {
        id: 'email',
        type: 'email',
        label: 'Email address',
        placeholder: 'enter ur email',
        name:'user_email',
        value : " email is required "
    },
    {
        id: 'message',
        type: 'textarea',
        label: 'Your message',
        placeholder: 'How can we help you? Or you us?',
        name:'message',
        value : " message  is required "
    },
]
const Form = () =>
    {
        const form = useRef();
        const [ user_name , setName ] = useState('')
        const [user_email , setEmail ] = useState('')
        const [message , setMessage ] = useState('')
        const [error , setError] = useState(false)
        const sendEmail = (e) => {

            e.preventDefault();

            if(user_name.length ==0|| user_email == 0 || message == 0 ) {
                setError(true)

            }
              else {
                emailjs
                    .sendForm(
                        "service_5mahi",
                        "template_fu31jef",
                        form.current,
                        e.target,
                        "B1zbCh6OTCiPMnlpO"
                    )
                    .then(
                        swal({
                            title: "Successfully sent!",
                            text: "We Received Your Message Thank You !",
                            icon: "success",
                            button: "ok",
                        })
                    );
                e.target.reset();
                window.location.reload();
            }
        };
        return(
            // <form className="form" ref={form} onSubmit={sendEmail} action=''>
            //     <h2 className="form-h2">Send us a message</h2>
            //     {formInputs.map(input => (
            //         <label key={input.label} id={input.id} className="form-label">
            //             {input.label}
            //
            //             {input.type === 'textarea' ? (
            //                 <textarea className="form-textarea" placeholder={input.placeholder}/>
            //             ) : (
            //                 <input
            //                     className="form-input"
            //                     type={input.type}
            //                     placeholder={input.placeholder}
            //                     name={input.name}
            //                     value={e=>setIn(e.target.value)}
            //                     // value={input.value}
            //
            //                 />
            //             )}
            //         </label>
            //     ))}
            //
            //     <button className="form-submit" type="submit" >
            //         Send message
            //     </button>
            // </form>
            <form className="form" ref={form} onSubmit={sendEmail}  action="">
                <div>
                    <h2 className="form-h2">Send us a message</h2>
                    {error && user_name.length <= 0?
                        <label className="form-label-red"> name is required</label>:""}
                    <input onChange={e=>setName(e.target.value)} className="form-input" type="text" name="user_name" placeholder="Enter your name here " />
<br/>
                    {error && user_email.length <= 0 ?
                        <label className="form-label-red"> email is required</label>:""}
                    <input onChange={e=>setEmail(e.target.value)} className="form-input" type="email" name="user_email" placeholder="Enter your email here " />

                    <br/>
                    {error && message.length <= 0 ?
                        <label className="form-label-red"> Message is required</label>:""}
                    <textarea onChange={e=>setMessage(e.target.value)} className="form-textarea" type="text" name="message" placeholder="How can we help you? Or you us?" />

                    <br/>
                    <button >send us</button>
                </div>
            </form>
)
}


export default Form
