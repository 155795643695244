import React from 'react';
import '../Home/Cards.css';
import CardItem from '../Home/CardItem';
import car1 from '../../data/image/aTapiseri/tapiseri/t1.png.jpg'
import car2 from '../../data/image/aTapiseri/tapiseri/t2.png.jpg'
import car3 from '../../data/image/aTapiseri/tapiseri/t3.png.jpg'
import car4 from '../../data/image/aTapiseri/t12.png.jpg'
import car5 from '../../data/image/aTapiseri/tapiseri/t5.png.jpg'
import car6 from '../../data/image/aTapiseri/tapiseri/t6.png.jpg'


function Tapiseries() {
    return (
        <div className='cards'>
            <h1>Our Tapiseri Services</h1>
            <h5>Explore the Conformable  & Beautifully Car sit </h5>
            <div className='cards__container'>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                        <CardItem
                            src={car1}
                            text='Beauty'
                            label='Luxury'
                            path='/service'
                        />
                        <CardItem
                            src={car2}
                            text='Comfort'
                            label='Luxury'
                            path='/service'
                        />
                        <CardItem
                            src={car3}
                            text='Long duration'
                            label='Luxury'
                            path='/service'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                            src={car4}
                            text='New Model'
                            label='Luxury'
                            path='/service'
                        />
                        <CardItem
                            src={car5}
                            text='With Your Color  '
                            label='Luxury'
                            path='/service'
                        />
                        <CardItem
                            src={car6}
                            text='Fair Price '
                            label='Luxury'
                            path='/service'
                        />
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Tapiseries;
