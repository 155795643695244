import React from 'react'
import forest  from "../../data/image/fo2.png"
import beteknet from "../../data/image/betekhnet.jpg"
import sodera  from "../../data/image/so.jpg"

import alert from "../../data/image/al.jpg"
import awash  from "../../data/image/awash.png"

import "./ourClients.css"
const OurClients =(props)=>{
    return(
        <>
            <h1>Our Clients</h1>
            <div>
            <div className="clients">
                <img src={forest} alt="" className="img-client"/>
                <img src={sodera} alt="" className="img-client"/>
                <img src={beteknet} alt="" className="img-client"/>

             </div>
                <br/>
                <div className="bottom-client">
                <img src={awash} alt="" className="sodera-client"/>
                    <img src={alert} alt="" className="sodera-client"/>

                </div>
            </div>
        </>
    )
}
export default OurClients;
